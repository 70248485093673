import React, { memo } from 'react';
import Layout from "../components/layout/layout";

const days = [
  {
    "name": "Maandag",
    "frenchName": "Lundi",
    "englishName": "Monday",
    "soup": "Wortelsoep",
    "frenchSoup": "Soupe de carottes",
    "englishSoup": "Carrot soup",
    "mainCourse": "Varkensragout met aardappelen uit de oven",
    "frenchMainCourse": "Ragout de porc, pommes de terre au four",
    "englishMainCourse": "Pork ragout with baked potatoes",
    "dessert": null
  },
  {
    "name": "Dinsdag",
    "frenchName": "Mardi",
    "englishName": "Tuesday",
    "soup": "Bloemkoolsoep",
    "frenchSoup": "Soupe au chou-fleur",
    "englishSoup": "Cauliflower soup",
    "mainCourse": "Geroosterde kippenbout, Provençaalse saus, rijst",
    "frenchMainCourse": "Cuisse de poulet au four, sauce provençale, riz",
    "englishMainCourse": "Oven-baked chicken thigh, Provençal sauce, rice",
    "dessert": null
  },
  {
    "name": "Woensdag",
    "frenchName": "Mercredi",
    "englishName": "Wednesday",
    "soup": "Groenteroomsoep",
    "frenchSoup": "Crème de légumes",
    "englishSoup": "Vegetable cream soup",
    "mainCourse": "Cordon bleu met kaassaus en frieten",
    "frenchMainCourse": "Cordon bleu avec sauce au fromage et frites",
    "englishMainCourse": "Cordon bleu with cheese sauce and fries",
    "dessert": null
  },
  {
    "name": "Donderdag",
    "frenchName": "Jeudi",
    "englishName": "Thursday",
    "soup": "Vissoep",
    "frenchSoup": "Soupe de poissons",
    "englishSoup": "Fish soup",
    "mainCourse": "Varkensspiering met champignonsaus, aardappelen, erwten en wortelen",
    "frenchMainCourse": "Spiringue de porc avec sauce aux champignons, pommes de terre, petits pois et carottes",
    "englishMainCourse": "Pork spiring with mushroom sauce, potatoes, peas, and carrots",
    "dessert": null
  },
  {
    "name": "Vrijdag",
    "frenchName": "Vendredi",
    "englishName": "Friday",
    "soup": "Brunoise groentesoep",
    "frenchSoup": "Soupe de legumes brunoise",
    "englishSoup": "Vegetable brunoise soup",
    "mainCourse": "Vislasagne",
    "frenchMainCourse": "Lasagne de poisons",
    "englishMainCourse": "Fish lasagna",
    "dessert": null
  },
  {
    "name": "Zaterdag",
    "frenchName": "Samedi",
    "englishName": "Saturday",
    "soup": "Tomaat-basilicumsoep",
    "frenchSoup": "Soupe tomate basilic",
    "englishSoup": "Tomato basil soup",
    "mainCourse": "Kippendij op landelijke wijze",
    "frenchMainCourse": "Cuisse de poulet façon campagnarde",
    "englishMainCourse": "Country-style chicken leg",
    "dessert": null
  },
  {
    "name": "Zondag",
    "frenchName": "Dimanche",
    "englishName": "Sunday",
    "soup": "Broccolisoep",
    "frenchSoup": "Potage de brocoli",
    "englishSoup": "Broccoli soup",
    "mainCourse": "Eendenpoot confit met bosvruchtensaus en aardappelpuree",
    "frenchMainCourse": "Cuisse de canard confite sauce de foret, purée pomme de terre",
    "englishMainCourse": "Confit duck leg with forest fruit sauce and mashed potatoes",
    "dessert": null
  }
];
const alternativeMenu = {
  "dutch": "Stoofvlees met frietjes",
  "french": "Carbonnade avec frites",
  "english": "Carbonnade with fries"
};
const Lunch = () => {
  return (
      <>
          <Layout/>
          <main className="main">
              <section className='lunch'/>
              <div className="best_price">
                  <img
                      loading='lazy'
                      src="/images/best_price_2.webp"
                      alt="Lunch"
                  />
              </div>
              <div className="top_title">
                  <h1>Lunch</h1>
              </div>

              <div className='lunch_wrap'>
                  <div className='lunch_box'>
                      <h3>7/10 - 13/10</h3>
                      <h3>11:30 – 15:00</h3>
                              {/* Альтернативное меню */}
                      <div className="alternative_menu">
                          <h3 style={{color:'#ae975f'}}>Alternatief gerecht voor deze week:</h3>
                          <p style={{color:'#ae975f'}}>{alternativeMenu.dutch} - {alternativeMenu.french} - {alternativeMenu.english}</p>
                      </div>      
                      {days.map((day, index) => (
                          <div key={index}>
                              <h4>{day.name} – {day.frenchName} – {day.englishName}</h4>
                              <p>{day.soup} - {day.frenchSoup} - {day.englishSoup}</p>
                              <p>{day.mainCourse}</p>
                              <p>{day.frenchMainCourse}</p>
                              <p>{day.englishMainCourse}</p>
                              {day.dessert && (
                                  <p>{day.dessert} - {day.frenchDessert} - {day.englishDessert}</p>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </main>
      </>
  );
};


export default memo(Lunch);
